.top-nav {
  // margin-left: var(--sidebar-width);
  height: var(--sidebar-width);
  background-color: white;
  align-items: center;
  display: flex;
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
  margin-bottom: 3em;

  position: sticky;
  top: 0;
  z-index: 10;
}