@import "../../../node_modules/bootstrap/scss/bootstrap";

/** Additional Variables */
$bd-violet: #6A42EB;

:root {
  --bd-violet-bg: #6A42EB;
  --bd-violet-rgb: rgb(118, 65, 240);

  --sidebar-width: 80px;
  --sidebar-width-open: 246px;

  --neutral--100: white;
  --neutral--200: #f5f6fa;
  --neutral--300: #e9eaf3;
  --neutral--400: #CACBD7;
  --neutral--500: #989AAD;
  --neutral--600: #6F7182;
  --neutral--700: #303350;
  --neutral--800: #0B0E2C;
  --general--shadow-02: rgba(11, 22, 44, .05);
  --accent--primary-1: #1476ff;
  --oe-blue: #247BFE;
  --oe-yellow: #FFB016;
  --green--300: #14CA74;
}

/** End Additional Variables */

.App {
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  line-height: unset;
  background-color: var(--neutral--200);
  min-height: 100vh;

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

input::placeholder {
  color: #ccc !important;
}

a {
  color: var(--accent--primary-1);
  transition: color 0.3s;
  text-decoration: none;

  &:hover {
    color: var(--neutral--500);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Inter, sans-serif;
  font-weight: 500;
}

h1 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.286em;
}

h2 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5em;
}

h3 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.417em;
}

h4 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.273em;
}

h5 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.333em;
}

h6 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.375em;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
}

@media screen and (min-width: 767px) {
  .container {
    --bs-gutter-x: 7.5rem;
  }
}

.btn {
  border: 1px solid var(--neutral--300);
  box-shadow: 0 4px 10px 0 var(--button-shadow--white-01);
  transform-style: preserve-3d;
  border-radius: 3em;
  padding: 0.8em 1.5em;
  line-height: 1.143em;
  transition: border-color .3s, transform .3s, background-color .3s, color .3s;

  &.active {
    border-color: var(--accent--primary-1);
    background-color: var(--accent--primary-1);
    box-shadow: 0 4px 10px 0 var(--button-shadow--color-2);
    color: var(--neutral--100);
  }
}

.hdr-logo-wrap {
  position: relative;

  #pb_oneethos {
    position: absolute;
    z-index: 3;
    left: 150px;
    bottom: 5px;
  }
}

.docs-submitted {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;

  .doc-success {
    display: flex;
    max-width: 8em;
    flex: 0;
    padding: 1em;
    font-size: 2.5em;
    flex-direction: column;
    background-color: darkseagreen;
    border-radius: 0.5em;
    color: white;
    align-items: center;

    i {
      font-size: 2em;
    }
  }

  p {
    font-size: 1.5em;
    padding: 1em;
  }

}

.doc-list {
  display: flex;
  flex-direction: row;

  .doc {
    display: flex;
    margin-right: 1em;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
    width: 120px;
    border-radius: 0.5em;
    text-align: center;
    background-color: #ddd;
    color: #333;

    span {
      width: 100%;
      word-wrap: break-word;
      overflow: hidden;
    }

    i {
      font-size: 3em;
    }
  }
}

.form-group {
  margin-bottom: 1em;

  input[type=checkbox] {
    height: 1.5em;
    width: 1.5em;
  }

  label {
    display: block;
  }
}

.doc-upload-item {
  .up-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0.1em;
  }

  p {
    margin: 0;
  }
}

.upload-prompt {
  .btn-group {
    margin: 0;
    padding: 0;
  }

  .btn {
    margin-top: 0.5em;
    display: inline-flex;
    align-items: center;
    align-self: stretch;

    svg,
    i {
      margin-right: 0.5em;
    }

    .file-name {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .detach {
    cursor: pointer;

    svg {
      margin: 0;
    }
  }

  &.multi-up {
    .file-cont {
      display: flex;
      flex-flow: row wrap;
    }

    .attached-file {
      display: flex;
      padding: 0.5em;
      position: relative;
      background-color: #ddd;
      margin: 0.5em;
      border-radius: 0.5em;
      flex-flow: column nowrap;
      align-items: center;
      width: 8em;

      .file-name {
        text-align: center;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.8em;
      }

      .detach {
        position: absolute;
        right: 0.5em;
        top: 0.5em;
      }

      .file-size {
        font-size: 0.8em;
        margin-left: 0.3em;
      }
    }

    .add-docs {
      background-color: #ddd;
      border-radius: 0.5em;
      border: 2px dashed #aaa;
      padding: 2.5em;
    }
  }
}

.comment {
  padding: 0.3em;
  border-radius: 0.3em;
  background-color: var(--bs-warning-bg-subtle);
}

.doc-status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0.7em;
  border-radius: 0.2em;
  background-color: #eee;
  margin-bottom: 0.5em;

  .file-label {
    flex: 1;
  }

  &>* {
    margin: 0.2em;
    // flex: 1;
  }

  .comment {
    flex: 1;
  }

  &.comments {
    .file-label {
      text-decoration: line-through;
      white-space: nowrap;
    }
  }
}

// overwrite boostrap defaults
.badge {
  text-transform: none;
  letter-spacing: 0;
}

.installer-profile {
  font-size: 16px;
}

.installer-verification {
  font-size: 16px;

  .ready-for-code {
    input {
      max-width: 100px;
    }
  }
}

.buttons {
  button {
    margin-right: 1em;
  }
}

.pos-view-toggle {
  text-align: center;

  button {
    i {
      margin-right: 0.3em;
    }
  }
}

.registration-prompt {
  h2 {
    font-family: "Segoe UI",
      "Segoe UI Web (West European)",
      "Segoe UI",
      -apple-system,
      BlinkMacSystemFont,
      Roboto,
      "Helvetica Neue",
      sans-serif;
  }
}

.mini-section {
  .alert {
    padding: 2rem;
  }
}


.project-docs {
  .doc {
    margin-bottom: 0.3em;

    textarea {
      font-size: 0.9em;
    }
  }

  .instructions {
    font-size: 0.9em;
  }
}

.doc-main {
  display: flex;
  min-width: 0;
  justify-content: space-between;
  align-items: center;

  .meta {
    flex: 1;
    min-width: 0;

    a {
      display: block;
    }

    &>* {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .filename {
      font-size: 0.8em;
      color: #aaa;
    }
  }

  .doc-action {
    flex: 0;
    margin-left: 0.4em;

    button {
      font-size: 0.9em;
    }
  }
}

.hdr-mini-nav {
  display: flex;
  align-items: center;
}

.installer-profile-btn {
  cursor: pointer;
  display: flex;
  color: rgb(11, 14, 44);
  align-items: center;
  padding: 0.5em;
  text-align: center;
  margin-right: 1em;

  svg {
    margin-right: 0.5em;
  }

  .username {
    margin-left: 0.5em;
  }
}

.oe-footer {
  text-align: center;

  .powered-by {
    display: inline-flex;
    text-align: center;
    padding: 1em;
    margin-bottom: 1.5em;
    border-radius: 0.5em;
    // background-color: #247BFE;
  }
}

.oe-pmt-summary {
  font-weight: bold;
  font-size: 16;

  .text-block-3 {
    font-weight: normal;
  }

  .no-autopay-pmt {
    .text-block-3 {
      font-size: 16px;
    }
  }
}

.validated-input {
  position: relative;

  input.error {
    border-color: red;
  }

  p.error {
    color: red;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.multi-select {
  display: inline-block;

  .list-group-item {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }
  }

  .chk-cont {
    width: 1.5em;
    height: 1.5em;
    text-align: center;
  }
}

.address-inputs {
  margin-bottom: 1em;
}

.submit-application {
  font-size: 1.2em;
}

.submitting {
  text-align: center;
}

.search-sugg {
  position: relative;

  .dd {
    position: absolute;
    width: 100%;
    z-index: 10;

    .list-group-item {
      cursor: pointer;

      &:hover {
        background-color: #fafafa;
      }
    }
  }
}

[class^="link-"] {
  cursor: pointer;
}

.table-list-wrap {
  max-width: 100%;
  overflow-x: scroll;
}

.installer-admin-page {
  .installer-row {
    vertical-align: middle;

    .email {
      display: flex;
      justify-content: space-between;

      .installer-actions {
        display: flex;
        align-items: center;

        &>* {
          margin-left: 0.3em;
          font-size: 1.3em;
        }
      }
    }
  }
}

.consumer-applications-page {
  .lapp {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.impersonate-warning {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 10;
  margin: 0 auto;
}

.btn-alt {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #{shade-color($bd-violet, 10%)};
  --bs-btn-hover-border-color: #{shade-color($bd-violet, 10%)};
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #{shade-color($bd-violet, 20%)};
  --bs-btn-active-border-color: #{shade-color($bd-violet, 20%)};
}


.nav {
  margin-bottom: 1em;
  font-weight: 1.1em;

  .nav-item {
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;
}