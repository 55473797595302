.credit-report {

  h5,
  h4,
  h6 {
    // font-family: Helvetica, sans-serif;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-weight: 600;
  }

  td.amt {
    text-align: right;
  }

  .trades {
    td {
      vertical-align: middle;
    }

    .trade-dates div {
      width: 150px;
      font-size: 0.8em;
      display: flex;
      justify-content: space-between;
    }

    .trade-history-grid {
      font-size: xx-small;

      .year {
        display: flex;
        flex-flow: row;
      }

      .months {
        display: flex;
        flex-flow: row-reverse;

        div {
          text-align: center;
          padding: 0.1em;
          border: 1px solid #ccc;
          width: 1.5em;
        }

        .year-label {
          width: 3em;
        }
      }

      .months.m-hdr {
        flex-flow: row;
      }
    }
  }

  .cr-scoring {
    .score {
      font-size: 1.3em;
    }
  }

  .cr-reject {
    color: orange;
  }

  .denial {
    color: red;
  }

  .section-hdr {
    font-size: 1.2em;
    cursor: pointer;
  }

  .cr-summary {
    display: flex;
    flex-direction: row;

    &>* {
      flex: 1;
    }
  }
}