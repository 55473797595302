.project {
  padding: 0;
  border-radius: 0.4em;
  margin: 0 0 1em;
  overflow: hidden;

  &>* {
    padding: 0.5em;
  }

  .hdr {
    position: relative;
    font-size: 1.1em;
    padding-left: 2.4em;

    .pr-type {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0.2em;
      padding: 0.5em;
      width: 2em;
      font-size: 1.2em;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .hdr-cont {
      // margin-left: 2.5em;
      display: flex;
      justify-content: space-between;
    }

    .pr-name {
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-right: 0.7em;

      .badge {
        margin: 0 0.8em;
      }
    }

    a {
      color: var(--bs-light);
    }

    background-color: var(--neutral--700);
    color: var(--bs-light);

    &.non-profit {
      background-color: #8FC3FF;
    }

    &.residential {
      background-color: #6295A2;
    }

    &.commercial {
      background-color: #9BB0C1;
    }

    &.closed {
      background-color: var(--bs-light);
      color: var(--bs-tertiary-color);

      a {
        color: var(--bs-tertiary-color);
      }
    }
  }

  .project-actions {
    .link {
      margin: 0.3em 0;
    }
  }

  .pr-info {
    margin: 0 0 0.4em 0;
  }

  .pr-closed {
    font-size: 0.7em;
  }

  .pr-needs-action {
    background-color: var(--oe-yellow);
    color: var(--neutral--700);
  }

  .pr-content {
    display: flex;
    font-family: Arial, Helvetica, sans-serif;

    &>* {
      margin-right: 0.4em;
    }
  }

  .st-descr {
    color: #aaa;
  }

  .label {
    font-weight: bold;
  }

  .detail {
    margin-bottom: 0.2em;
  }

  .assignees {
    align-items: center;
    justify-content: flex-start;

    .ass-list {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .ass {
        font-size: 0.9em;
        border-radius: 1em;
        background-color: #EEF9FF;
        color: var(--neutral--700);
        border: 1px solid var(--neutral--300);

        &.inactive {
          text-decoration: line-through;
          background-color: #ddd;
        }
      }
    }
  }

  .due-dt {
    display: inline-block;
    color: red;
  }
}

@media (max-width: 600px) {
  .pr-content {
    display: flex;
    flex-direction: column;

    &>* {
      margin: 0 0 0.5em;
    }
  }

  .project .hdr .pr-name {
    flex-direction: column;
    align-items: flex-start;

    .badge {
      margin: 0.3em 0;
    }
  }
}