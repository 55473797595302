
.isc-editor {
  .isc-statuses {
    .list-group-item {
      &:hover {
        background-color: #eee;
      }
    }
  }

  .isc-item {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eee;
    margin-bottom: 1em;

    & > * {
      flex: 1;
    }

    & > *:first-child {
      margin-right: 1em;
    }
  }
}

.doc-types {
  .doc-type {
    display: flex;
    &>*:first-child {
      margin-right: 1em;
    }

    textarea {
      font-size: 0.8em;
    }

    .actions {
      padding: 0.8em;
    }
  }
}